import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBan } from '@fortawesome/pro-regular-svg-icons';

import { CheckIcon, MinusIcon } from 'assets/icons';

import { Chip } from '@mui/material';

import { Center, Grid, Group, Stack, Text } from '@mantine/core';

import { getChipValue } from 'pages/MonitorResults';

import BlacklistMatrixItem from './components/BlacklistMatrixItem';

import './styles.scss';

const matrixBackgroundColorByTier = {
  1: '#2D314B',
  2: '#282C42',
  3: '#23273B',
};

const BlacklistMatrix = ({
  generalState,
  mobileWidth,
  items,
  withoutTitle,
  scrollToServiceItem,
  disablePopover,
}) => {
  const tiers = items.reduce((acc, item) => {
    if (!acc.includes(item.tier)) {
      acc.push(item.tier);
    }

    return acc;
  }, []).sort((a, b) => a - b);

  return (
    <div className={cn(
      'monitor-result-group-section',
      'pagebreak',
      {
        'no-paddings': withoutTitle,
      },
    )}
    >
      {!withoutTitle && (
      <div className="group-section-title">
        <div className="align-items-center">
          {generalState && (
          <div className={cn('group-status-mark', !generalState.status && 'group-status-mark-fail')}>
            {generalState.status ? <CheckIcon /> : <MinusIcon />}
          </div>
          )}

          <div className="group-section-text">
            <p>Blacklist matrix</p>
          </div>
        </div>

        <div className="chip-and-arrow-wrapper">
          {generalState && (
          <div className="chip-wrapper">
            <Chip
              className="chip"
              label={getChipValue({ state: generalState, mobileWidth, isClean: true })}
              variant={generalState.value === 'Fail' ? 'filled' : 'outlined'}
              color={generalState.color}
            />
          </div>
          )}
        </div>
      </div>
      )}

      <div
        className={cn('blacklist-matrix-wrapper', {
          'no-paddings': withoutTitle,
        })}
      >
        <Stack spacing={2}>
          {tiers.map((tier, tierIndex, arr) => {
            const firstTierPerRow = 7;
            const otherTiersPerRow = 11;

            const itemsPerRow = tier === 1 ? firstTierPerRow : otherTiersPerRow;

            const rows = items.filter((item) => item.tier === tier);

            const totalItemsInFullRows = Math.ceil(rows.length / itemsPerRow) * itemsPerRow;
            const placeholdersCount = totalItemsInFullRows - rows.length;

            const placeholders = Array.from({ length: placeholdersCount }).map((_, i) => ({
              '@id': `placeholder-${i}`,
              title: '',
              tier,
              isPlaceholder: true,
            }));

            const rowsWithPlaceholders = [...rows, ...placeholders];
            const rowsCount = Math.ceil(rowsWithPlaceholders.length / itemsPerRow);

            const isSmall = tier !== 1 && rowsCount <= 1;

            return (
              <Group key={tier} spacing={2} align="stretch">
                {/* Fixed width Tier label */}
                <Center
                  w={36}
                  sx={{
                    backgroundColor: matrixBackgroundColorByTier[tier],
                    ...(tierIndex === 0 && { borderTopLeftRadius: 10 }),
                    ...(tierIndex === arr.length - 1 && { borderBottomLeftRadius: 10 }),
                  }}
                >
                  <Group
                    position="center"
                    spacing={isSmall ? 6 : 12}
                  >
                    {/* <FontAwesomeIcon
                      icon={faBan}
                      color="#F02B4F"
                      fontSize={isSmall ? 10 : 14}
                    /> */}

                    <Text
                      className="tier-label"
                      style={{
                        ...(isSmall && { fontSize: 9 }),
                      }}
                    >
                      TIER {tier}
                    </Text>
                  </Group>
                </Center>

                {/* Main content grid */}
                <Grid gutter={2} sx={{ flex: 1 }}>
                  <Grid.Col>
                    <Grid gutter={2} columns={itemsPerRow}>
                      {rowsWithPlaceholders.map((item, tierRowsIndex, rowArr) => (
                        <Grid.Col
                          span={1}
                          key={item['@id']}
                        >
                          <BlacklistMatrixItem
                            item={item}
                            backgroundColor={matrixBackgroundColorByTier[tier]}
                            failed={item.state?.value === 'Fail'}
                            firstTierPerRow={firstTierPerRow}
                            tierIndex={tierIndex}
                            tierRowsIndex={tierRowsIndex}
                            tiersLength={arr.length}
                            tierRowsLength={rowArr.length}
                            scrollToServiceItem={scrollToServiceItem}
                            disablePopover={disablePopover}
                          />
                        </Grid.Col>
                      ))}
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Group>
            );
          })}
        </Stack>
      </div>
    </div>
  );
};

BlacklistMatrix.propTypes = {
  generalState: PropTypes.shape().isRequired,
  mobileWidth: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  withoutTitle: PropTypes.bool,
  scrollToServiceItem: PropTypes.func,
  disablePopover: PropTypes.bool,
};

BlacklistMatrix.defaultProps = {
  withoutTitle: false,
  scrollToServiceItem: null,
  disablePopover: false,
};

export default React.memo(BlacklistMatrix);
